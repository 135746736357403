/* @tailwind base; comentado por dar conflito com reset dos components do antd */
@tailwind components;
@tailwind utilities;

* {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
