.chat-start::before,
.chat-end::after {
    content: '';
    position: relative;
    width: 0;
    height: 0;
    border-style: solid;
}

.chat-start::before {
    border-width: 10px 15px 0 0;
    border-color: transparent #d4d4d8 transparent transparent;
    top: 100%;
    left: 0px;
    transform: translateY(-100%);
}

.chat-end::after {
    border-width: 10px 0 0px 15px;
    border-color: transparent transparent transparent #a7c9cb;
    top: 100%;
    right: 0px;
    transform: translateY(-100%);
}

.chat-message-system {
    border: 2px solid #a7c9cb;
    background-color: #a7c9cb;
    color: '#52525b';
    border-radius: 10px 10px 0 10px;
    max-width: 60%;
    padding: 5px;
}

.chat-message-recipient {
    border: 2px solid #d4d4d8;
    background-color: #d4d4d8;
    color: '#52525b';
    border-radius: 10px 10px 10px 0;
    max-width: 60%;
    padding: 5px;
}

.custom-scrolbar-chat::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
}

.custom-scrolbar-chat::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
}

.custom-scrolbar-chat::-webkit-scrollbar-thumb {
    background-color: #7aaeaf; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: none;
}

.custom-scrolbar-list::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
}

.custom-scrolbar-list::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
}

.custom-scrolbar-list::-webkit-scrollbar-thumb {
    background-color: white; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid #611ca0; /* creates padding around scroll thumb */
}

.linear {
    background-color: linear-gradient(45deg, #3f87a6, #ebf8e1, #f69d3c) !important;
}

.modal-chat .ant-modal-content {
    border: none;
    padding: 0;
}

.modal-chat .ant-modal-close-x {
    color: white;
}
