/* ---------------------------------------- */
/* --------------- SCROLL ----------------- */
/* ---------------------------------------- */

/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: var(#092121) #092121;
}

/* Chrome, Edge, and Safari */
::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
    background: white; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
    background-color: #092121; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid white; /* creates padding around scroll thumb */
}

/* Fonts */
@font-face {
    font-family: 'MontserratFont';
    src: url('./fonts/Montserrat-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.montserrat-light-font,
.montserrat-light-font .ant-btn {
    font-family: 'MontserratFont', sans-serif !important;
}